<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.lineasfacturacion') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <lineasfacturacion-estados />
                </div>
                <div class="col-md-10">
                    <tabla-datos 
                    :columnas="columnas" 
                    :datos="datos" 
                    :filtros="filtros" 
                    :filtrosBuscador="filtrosBuscador"
                    :total="total"
                    @quieroDatos="obtenerDatos">
                    </tabla-datos>      
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import LineasFacturacionEstados from './listado/LineasFacturacionEstados.vue'
import TablaDatos from '../../TablaDatos.vue';
import { PwgsApi } from '../../../services/PwgsApi';
import { FilterMatchMode } from 'primevue/api';
export default {
    components: {
        'tabla-datos': TablaDatos,
        'lineasfacturacion-estados': LineasFacturacionEstados,
    },
    props: {
        id: {
            type: Number,
            default: 0,
        }
},
    data() {
        return {
            total:'',
            datos: [],
            eliminado: false,
            parametros: null, // query params de DataTable
            filtrosBuscador: ['nombre_serie', 'prefijo_abono_serie','prefijo_factura_serie'], // filtros para el buscador
            filtros: { // filtros de columna
                'nombre_serie': { value: '', matchMode: FilterMatchMode.CONTAINS },
                'prefijo_factura_serie': { value: '', matchMode: FilterMatchMode.CONTAINS },
                'prefijo_abono_serie': { value: '', matchMode: FilterMatchMode.CONTAINS },
            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre_serie',
                    sortable: true,
                },
                {
                    header: 'Prefijo Factura',
                    field: 'prefijo_factura_serie',
                    sortable: true,
                },
                {
                    header: 'Prefijo Abono',
                    field: 'prefijo_abono_serie',
                    sortable: true,
                },
                {
                    header: 'Dígitos',
                    field: 'digitos_numero_factura',
                },
                {
                    header: 'Inicio',
                    field: 'tope_serie',
                },
            
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                this.$router.push({
                                    name: 'Línea Facturación',
                                    params: {
                                        id:id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar linea ' + id);
                                if (confirm('Desea eliminar la linea ' + id)) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ],
        }
    },
    methods: {
        async obtenerDatos(parametros) {
            /* get  modulos/pwgsapi/index.php/lineas-facturacion */
            const api = new PwgsApi();
            this.$store.dispatch('cambiarCargando', true);
            const params = parametros;
            const datos = await api.get('lineas-facturacion', params);
            this.total = datos.n_total_registros;
            this.datos = datos.datos;
            this.$store.dispatch('cambiarCargando', false);
            this.eliminado = false;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.prefijofactura");
            this.columnas[2].header = this.$t("general.prefijoabono");
            this.columnas[3].header = this.$t("general.digitos");
            this.columnas[4].header = this.$t("general.inicio");
            this.columnas[5].header = this.$t("general.acciones");


        },
        async eliminar(id) {
            /* delete  modulos/pwgsapi/index.php/lineas-facturacion/:id */
            const api = new PwgsApi();
            await api.delete('lineas-facturacion/' + id);
            this.obtenerDatos(this.parametros);

        },
    },
    activated() {
        this.obtenerDatos(this.parametros);
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>